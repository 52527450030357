class FormValidation {

    static init() {
        FormValidation.scrollToError();
        //
        FormValidation.vivalid();
        // Init recaptcha
        FormValidation.initRecaptcha();
    }

    /**
     * Init vivalid validation
     */
    static vivalid() {
        // http://www.pazams.com/vivalid/documentation/
        const addCallback = vivalid.htmlInterface.addCallback;
        const initAllValidations = vivalid.htmlInterface.initAll;

        // required, submit form on validation success
        addCallback('onValidationSuccess', function () {
            const form = this.closest('form');

            if(FormValidation.formHasRecaptcha(form)) {
                FormValidation.handleRecaptchaSubmit(form);
            } else {
                form.submit();
            }
        });

        // required, do something on validation failure (can use property - invalid, pending, valid)
        addCallback('onValidationFailure', function (invalid, pending, valid) {
            // console.log(invalid, pending, valid); // number of invalid, pending and valid inputs
        });

        // used to display custom UI
        addCallback('onInputValidationResult', function(el, validationsResult, validatorName, stateEnum) {
            let msgEl = el.nextElementSibling;

            if (validationsResult.stateEnum === stateEnum.invalid) {
                el.classList.add('form-input--error');
                msgEl.classList.add('form-error--is-active');
                msgEl.innerHTML = validationsResult.message;
            } else {
                el.classList.remove('form-input--error');
                msgEl.classList.remove('form-error--is-active');
                msgEl.innerHTML = '';
            }
        });

        initAllValidations();
    }

    /**
     * Init recaptcha
     *
     * @param form
     * @private
     */
    static initRecaptcha() {
        var resolved = false;
        var items = document.getElementsByClassName('g-recaptcha');
        var length = items.length;

        if (length === 0) {
            return;
        }

        grecaptcha.ready(function () {
            // Render and process
            for (var i = 0; i < length; i++) {
                grecaptcha.render(items[i]);
            }
        });
    }

    /**
     * Recaptcha submit handler
     *
     * @param {HTMLElement} form
     */
    static handleRecaptchaSubmit(form) {
        var resolved = false;

        grecaptcha.execute().then(function (token) {
            resolved = true;

            // reCaptcha token expires after 2 minutes; make it 5 seconds earlier just in case network is slow
            window.setTimeout(function () {
                resolved = false;
            }, (2 * 60 - 5) * 1000);

            var input = form.querySelector('.g-recaptcha');
            input.value = token;

            form.submit();
        });
    }

    /**
     * @param form
     * @returns {boolean}
     * @private
     */
    static formHasRecaptcha(form) {
        var items = form.getElementsByClassName('g-recaptcha');

        if (items.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * On page load load to (nette) error if exists
     */
    static scrollToError() {
        let error = document.querySelector('.form-error-nette');

        if(error) {
            window.setTimeout(function() {
                error.scrollIntoView({
                    behavior: "smooth",
                    alignToTop: true
                });
            }, 1000);
        }
    }
}
