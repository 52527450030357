
class Gallery {

    static init() {
        const mainGalleries = document.getElementsByClassName('js-gallery-main'),
            thumbsGalleries = document.getElementsByClassName('js-gallery-thumbs');

        let newMainG, newThumbsG;

        if (mainGalleries && thumbsGalleries && ( mainGalleries.length === thumbsGalleries.length ) ) {
            for (let i = 0; i < mainGalleries.length; ++i) {

                newMainG = tns({
                    loop: false,
                    container: mainGalleries[i],
                    navContainer: thumbsGalleries[i],
                    items: 1,
                    navAsThumbnails: true,
                    mouseDrag: false,
                    controls: false
                });

                newThumbsG = tns({
                    loop: false,
                    container: thumbsGalleries[i],
                    items: 4,
                    mouseDrag: true,
                    nav: false,
                    controls: false
                });
            }
        }
    }
}

class GalleryGroundplan {

    static changeMainGroundplan() {
        const smallImages = document.querySelectorAll('.js-small-image-link');

        for (let i = 0; i < smallImages.length; ++i) {
            smallImages[i].addEventListener('click', function(e) {
                e.preventDefault();

                for (let x = 0; x < smallImages.length; ++x) {
                    smallImages[x].classList.remove('active');
                }

                let bigImage = document.getElementById('js-groundplan-big-img');
                let smallImageSingle = smallImages[i];

                bigImage.src = smallImageSingle.getAttribute('href');

                smallImageSingle.classList.add('active');
            });
        }
    }
}