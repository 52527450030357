
class ToggleSwitch {

    static toggle() {
        const btn = document.getElementById('toggleSections'),
            map = document.getElementById('sectionOne'),
            text = document.getElementById('sectionTwo');

        if (btn && map && text) {
            btn.addEventListener('click', function() {
                map.classList.toggle('col--hidden');
                text.classList.toggle('col--hidden');
            });
        }
    }
}
