
document.addEventListener('DOMContentLoaded', (event) => {

    // smoothscroll plugin has to be install as npm package
    let scroll = new SmoothScroll('a[data-scroll]', {
        header: '[data-scroll-menu]'
    });

    /*
    let doneOnce = false;
    window.addEventListener('scroll', function () {
        let isOnViewPort = isScrolledIntoView.init( document.getElementsByClassName('js-number-count')[0] );

        if (isOnViewPort && !doneOnce) {
            Counter.init(document.getElementsByClassName('js-number-count'));
            doneOnce = true;
        }
    });
     */

    //Selects.ChoicesJS();

    PhotoSwipePlugin.init();
    //PhotoSwipePlugin.presenter();

    Menu.setScrolling();
    Slider.init();
    ToggleSwitch.toggle();
    FormValidation.init();
    Gallery.init();
    GalleryGroundplan.changeMainGroundplan();

    Project.ctaBTN();

    LoadMore.init(
        document.getElementById('js-load-more-articles'),
        document.getElementById('js-load-more-articles-btn'),
        3,
        6,
        9,
        3,
        4,
        6
    );

    MicroModal.init({
        onClose: function(modal) {
            const iframe = modal.querySelector('.video__iframe');

            if (iframe) {
                iframe.src = iframe.src;
            }
        },
    });

    objectFitImages();


});
