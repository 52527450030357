class Project {

    static init() {
        let hiddenProjects = document.querySelectorAll('.js-project-hidden:not(.js-project-visible)');
        const btn = document.getElementById('show-more-projects');
        let end = 3;

        //console.log(hiddenProjects, 'hiddenProjects');

        if (hiddenProjects.length > 0) {
            let delay = 0;

            if (hiddenProjects.length < 3) end = hiddenProjects.length;
            //if (hiddenProjects.length === 1) end = 1;

            //console.log(hiddenProjects.length, end, 'h and end');

            for (let i = 0; i < end; i++) {
                setTimeout(function () {
                    hiddenProjects[i].style.display = 'block';
                    fadeIn(hiddenProjects[i], 1000);
                    hiddenProjects[i].classList.add('js-project-visible');
                }, delay);

                delay += 500;
            }
        }

        // hide shore more btn if there are not more hidden projects
        let visibleProjects = document.querySelectorAll('.js-project-visible');
        //console.log('1', visibleProjects.length + end, document.querySelectorAll('.js-project-hidden').length);
        if ( (visibleProjects.length + end) === document.querySelectorAll('.js-project-hidden').length && btn) btn.style.display = 'none';
    }

    static ctaBTN() {
        const btn = document.getElementById('show-more-projects');

        if ( btn ) {
            btn.addEventListener('click', function (e) {
                e.preventDefault();
                Project.init();
            });
        }
    }
}
