/**
 * Tabs.
 */
class Tab {

    /**
     * Set base tab logic. When click on tab link show relevant tab content.
     */
    static init() {
        const allTabLinks = document.getElementsByClassName('js-tab-link');

        if (allTabLinks) {
            for (let i = 0; i < allTabLinks.length; i++) {
                // event listener to tab links
                allTabLinks[i].addEventListener('change', () => {
                    // get parent element to move only in current tab for case that there are multiple instances of tabs
                    let parent = allTabLinks[i].closest('.tabs');

                    // get current link (which was clicked and fired event on change)
                    let currentTabLink = parent.querySelector('.js-tab-link:checked'),
                        currentItem = parent.querySelector('.tabs__item--active');

                    // get ID of new section from data attribute of current tab link
                    let sectionId = currentTabLink.getAttribute('data-section'),
                        newSection = document.getElementById(sectionId);

                    // hide old (previous) section
                    let oldSection = parent.querySelector('.tabs__content--active');
                    oldSection.classList.remove('tabs__content--active');
                    currentItem.classList.remove('tabs__item--active');

                    // set new section
                    newSection.classList.add('tabs__content--active');
                    allTabLinks[i].closest('.js-tab-item').classList.add('tabs__item--active');
                });
            }
        }
    }
}

