
class MicroModalP {

    static init() {
        const MicroModalTriggers = document.querySelectorAll('[data-micromodal-trigger]');
        for (let i = 0; i < MicroModalTriggers.length; ++i) {
            MicroModalTriggers[i].addEventListener('click', function(e) {
                e.preventDefault();
            });
        }
        MicroModal.init({
            disableScroll: true,
            awaitCloseAnimation: true
        });
    }
}
