
class Menu {

    /**
     * Set menu's height to body's padding top to make space for menu.
     */
    static setHeight() {
        const body = document.body;
        const menu = document.getElementById('menu-container');

        body.style.paddingTop = `${menu.offsetHeight}px`;
    }

    /**
     * Hide and show menu on scrolling.
     */
    static setScrolling() {
        let prevScrollPos = window.pageYOffset;

        function init() {
            let checkbox = document.getElementById('nav-toggle');
            let menu = document.getElementById('menu-container');
            let header = document.getElementById('intro');
            let currentScrollPos = window.pageYOffset;

            let pointWhereActiveMenu = header.offsetHeight;

            if ( header.classList.contains('sub-header-no-menu') ) pointWhereActiveMenu = 0;

            // if header's height is smaller than scrolled area start scrolling
            if (pointWhereActiveMenu <= currentScrollPos) {
                menu.classList.add('menu-container--is-scrolled');
            } else {
                menu.classList.remove('menu-container--is-scrolled');
                checkbox.checked = false;
            }

            prevScrollPos = currentScrollPos;
        }

        // show menu on load
        init();
        window.onscroll = function () {
            // init menu on load
            init();
        };
    }
}
