
class Slider {

    static init() {
        const sliderEl = document.getElementsByClassName('hp-slider');
        const sliderRefEl = document.getElementsByClassName('reference-slider');

        if (sliderEl.length > 0) {
            let slider = tns({
                mode: 'gallery',
                animateDelay: 300,
                container: sliderEl[0],
                items: 1,
                slideBy: 'page',
                autoplay: true,
                autoplayButtonOutput: false,
                controls: false,
                nav: false,
            });
        }

        let sliderRef;

        if (sliderRefEl.length > 0) {
                sliderRef = tns({
                animateDelay: 300,
                container: sliderRefEl[0],
                items: 1,
                slideBy: 'page',
                autoplay: false,
                autoplayButtonOutput: false,
                nav: true,
                controlsContainer: '#refControls'
            });
        }
    }
}
